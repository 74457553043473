<template>
	<v-container
		fluid
	>

		<v-row justify="center">
			<v-col cols="12">
				<v-card>
					<v-card-title>
						<v-spacer />
						<v-menu offset-y>
							<template v-slot:activator="{ on }">
								<Button v-on="on">
									<v-icon>mdi-plus</v-icon>
								</Button>
							</template>
							<v-list>
								<v-list-item
									v-for="(item, index) in add_items"
									:key="index"
									@click="add(item.event)"
								>
									<v-list-item-title>{{ item.title }}</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
					</v-card-title>

					<v-card-text>
						<v-data-table
							:loading="loading"
							:headers="headers"
							:items="items"
							:server-items-length="totalItems"
							:options.sync="options"
							item-key="id"
							:footer-props="{'items-per-page-options':[10,25,50]}"
						>
							<template v-slot:[`item.action`]="{ item }">
								<v-icon
									small
									class="mr-2"
									@click="edit(item)"
								>
									mdi-pencil
								</v-icon>
								<v-icon
									small
									class="mr-2"
									@click="deleteItem(item)"
								>
									mdi-delete
								</v-icon>
							</template>
							<template v-slot:[`item.source`]="{ item }">
								<template v-if="item.type === `twitter`">
									{{ item.source.from }} {{ item.source.q }}
								</template>
								<template v-if="item.type === `rss`">
									{{ item.source.url }}
								</template>
							</template>
							<template v-slot:[`item.verified`]="{ item }">
								<v-icon v-if="item.verified" color="green">mdi-check-bold</v-icon>
							</template>
							<template v-slot:[`item.published`]="{ item }">
								<v-icon v-if="item.published" color="green">mdi-check-bold</v-icon>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>


		<v-dialog v-model="editRss.show" max-width="600px">
			<v-card>
				<v-card-title>
					RSS Source
					<v-spacer />
					<Button icon text @click="cancel">
						<v-icon>mdi-close</v-icon>
					</Button>
				</v-card-title>

				<v-divider />

				<v-card-text>
					<v-container>
						<v-form ref="formRss">
							<v-text-field v-model="editRss.form.id" class="d-none"></v-text-field>
							<v-row>
								<v-col cols="12">
									<Input v-model="editRss.form.url" label="URL" required />
								</v-col>
							</v-row>
						</v-form>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<Button type="submit" @click="saveRss">Save</Button>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="editTwitter.show" max-width="600px">
			<v-card>
				<v-card-title>
					Twitter Source
					<v-spacer />
					<Button icon text @click="cancel">
						<v-icon>mdi-close</v-icon>
					</Button>
				</v-card-title>

				<v-divider />

				<v-card-text>
					<v-container>
						<v-form ref="formTwitter">
							<v-text-field v-model="editTwitter.form.id" class="d-none"></v-text-field>
							<v-row>
								<v-col>
									<Input v-model="editTwitter.form.account" label="Account" outlined placeholder="wedonthavetime0" required />
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<Input v-model="editTwitter.form.hashtag" label="Hashtag" outlined placeholder="wedonthavetime" required />
								</v-col>
							</v-row>
						</v-form>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<Button type="submit" @click="saveTwitter">Save</Button>
				</v-card-actions>
			</v-card>
		</v-dialog>

	</v-container>
</template>

<script>
import { globalMixIn } from '@/App.vue'

export default {
	data: () => ({
		loading: false,
		editRss: {
			show: false,
			form: {
				id: null,
				url: null,
			}
		},
		editTwitter: {
			show: false,
			form: {
				id: null,
				account: null,
				hashtag: null,
			}
		},
		options: {},
		totalItems: 0,
		headers: [
			{ text: `Source`, value: `source`, sortable: false },
			{ text: `Type`, value: `type`, sortable: false },
			{ text: `Actions`, value: `action`, align: `center`, sortable: false },
		],
		items: [],
		add_items: [
			{ title: `RSS`, event: `rss` },
			{ title: `Twitter`, event: `twitter` },
		],
	}),
	methods: {
		cancel() {
			this.editTwitter.show = false
			this.editRss.show = false
		},
		edit(item) {
			if (item.type === `twitter`) {
				this.editTwitter.form.id = item.id
				this.editTwitter.form.hashtag = item.source.q
				this.editTwitter.form.account = item.source.from
				this.editTwitter.show = true
			}
			if (item.type === `rss`) {
				this.editRss.form.id = item.id
				this.editRss.form.url = item.source.url
				this.editRss.show = true
			}
		},
		deleteItem(item) {
			this.$root.$confirm(`Delete`, `Are you sure?`, { color: `error` })
				.then((confirm) => {
					if (confirm) {
						this.$httpInt.delete(`/v2/news/sources/${item.id}`)
							.then(() => {
								this.$root.$snackbar(`News source deleted`)
								this.refreshContent()
							})
					}
				})
		},
		saveRss() {
			const data = {
				type: `rss`,
				id: this.editRss.form.id,
				url: this.editRss.form.url,
			}
			if (data.id) {
				this.$httpInt.patch(`/v2/news/sources/${data.id}`, data)
					.then(() => {
						this.cancel()
						this.$refs.formRss.reset()
						this.refreshContent()
					})
			} else {
				this.$httpInt.post(`/v2/news/sources/`, data)
					.then(() => {
						this.cancel()
						this.$refs.formTwitter.reset()
						this.refreshContent()
					})
			}

		},
		saveTwitter() {
			const data = {
				type: `twitter`,
				id: this.editTwitter.form.id,
				from: this.editTwitter.form.account,
				q: this.editTwitter.form.hashtag,
			}
			if (data.id) {
				this.$httpInt.patch(`/v2/news/sources/${data.id}`, data)
					.then(() => {
						this.cancel()
						this.$refs.formTwitter.reset()
						this.refreshContent()
					})
			} else {
				this.$httpInt.post(`/v2/news/sources/`, data)
					.then(() => {
						this.cancel()
						this.$refs.formTwitter.reset()
						this.refreshContent()
					})
			}
		},
		add(e) {
			if (e === `rss`) this.editRss.show = true
			if (e === `twitter`) this.editTwitter.show = true
		},
		refreshContent() {
			this.loading = true
			this.$httpInt.get(`/v2/news/sources`, { params: this.$paginationParams(this) })
				.then(res => {
					this.items = res.data.result
					this.totalItems = res.data.pagination.total
				})
				.finally(() => this.loading = false)
		},
	},
	mixins: [globalMixIn],
	mounted () {
		this.refreshContent()
	},
	name: `NewsRssSources`,
	watch: {
		options: {
			handler () {
				this.refreshContent()
			},
		},
	},
}
</script>
